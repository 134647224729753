import React, { useEffect } from 'react';
import DefaultLayout from '../components/DefaultLayout';
import { Link } from 'gatsby';

interface Props {}

const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

const CallConfirmed: React.FC = ({ window, location }) => {
  const base = location.pathname.replace(/\//g, '') || 'page';
  const title = capitalizeFirstLetter(base) + ` - Argento CPA`;
  useEffect(() => {
    typeof window !== 'undefined' &&
      window.gtag('event', 'conversion', { send_to: 'AW-854015093/eNLkCIrp4tgYEPX4nJcD' });
      console.log(`call_confirmed`)
  }, []);
  return (
    <DefaultLayout>
      <title>{title}</title>
      <div className='four-oh-four container max-w-lg mx-auto mt-10'>
        <h1 className='text-4xl text-primary-base mb-16 font-bold text-center'>Thank you for your message</h1>
        <p className='mb-16 text-center'>We will get back to you within 24 hours.</p>
        <Link className='text-primary-base text-center mx-auto block' to='/'>
          Go back to the Home Page
        </Link>
      </div>
    </DefaultLayout>
  );
};

export default CallConfirmed;
